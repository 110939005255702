import { AxiosResponse } from 'axios';
import { runInAction, makeAutoObservable } from 'mobx'
import { getDeliveryMethods, getOrderById, getPaymentMethods, postOrder } from '../../api/LayoutApi';
import { mapDeliveryMethodDtoToDeliveryMethod, mapOrderDetailDtoToOrderDetail, mapOrderDetailToOrderDetailPostDto, mapPaymentMethodDtoToPaymentMethod } from '../../mapping/ResponseMapping';
import { DeliveryMethod, DeliveryMethodDto, OrderDetail, OrderDetailDto, PaymentMethod, PaymentMethodDto } from '../../types/ResponseTypes';
import BonusesStore from '../BonusesStore/BonusesStore';
import CartStore from '../CartStore/CartStore';
import UserStore from '../UserStore/UserStore';

class OrderStore {
    constructor(userStore: UserStore, cartStore: CartStore, bonusStore: BonusesStore) {
        makeAutoObservable(this);
        this.userStore = userStore
        this.cartStore = cartStore
        this.bonusStore = bonusStore;
        
        this.currentOrder = this.getEmptyOrder()
    }

    userStore: UserStore
    cartStore: CartStore
    bonusStore: BonusesStore

    currentOrder: OrderDetail

    private deliveryMethodList?: Array<DeliveryMethod>
    private paymentMethodList?: Array<PaymentMethod>

    isDeliveryAndPaymentReady() {
        return ((this.currentOrder.payment_method != 0) && (this.currentOrder.delivery_method.id != 0))
    }

    isInvoiceReady() {
        return this.currentOrder.payment_name != ""
    }

    loadCurrentOrder() {
        const localOrder = JSON.parse(window.localStorage.getItem('order') || 'false') as OrderDetail
        if (localOrder) {
            this.currentOrder = localOrder
        }
    }

    async saveCurrentOrderToServer(): Promise<AxiosResponse<OrderDetailDto, any>> {
        await this.updatePrices();
        return postOrder(mapOrderDetailToOrderDetailPostDto(this.currentOrder), this.userStore.getJWT());
    }

    saveCurrentOrderToLocalStore() {
        window.localStorage.setItem('order', JSON.stringify(this.currentOrder));
    }

    clearCurrentOrderFromLocalStorage() {
        window.localStorage.removeItem('order')
    }

    async clearCurrentOrder() {
        this.clearCurrentOrderFromLocalStorage()
        this.currentOrder = this.getEmptyOrder()
        await this.cartStore.clear();
    }

    async loadDeliveryMethods(): Promise<DeliveryMethod[]> {
        if (this.deliveryMethodList) {
            return this.deliveryMethodList
        }

        if (this.userStore.isLogged) {
            const items = (await getDeliveryMethods(this.userStore.getJWT())).data
            if (items && items.data) {
                const result = items.data.map((e: DeliveryMethodDto) => mapDeliveryMethodDtoToDeliveryMethod(e))
                this.deliveryMethodList = result

                return result
            }
        }

        return []
    }    

    async loadPaymentMethods(): Promise<PaymentMethod[]> {
        if (this.paymentMethodList) {
            return this.paymentMethodList
        }

        if (this.userStore.isLogged) {
            const items = (await getPaymentMethods(this.userStore.getJWT())).data
            if (items && items.data) {
                const result = items.data.map((e: PaymentMethodDto) => mapPaymentMethodDtoToPaymentMethod(e))
                this.paymentMethodList = result

                return result
            }
        }

        return []
    }

    async updatePrices() {
        await this.cartStore.loadCartInfo();

        runInAction(() => {
            this.currentOrder.order_items = this.cartStore.cartItems.map(e => e.id);
            const total_price_with_tax = this.cartStore.getTotal() + this.currentOrder.real_delivery_cost;
            this.currentOrder.total_price_with_tax = Math.round(total_price_with_tax * 100) / 100; // Округление до двух знаков после запятой
            this.currentOrder.bonus_to_spend = this.bonusStore.countUsedBonuses;
        });
    }

    private getEmptyOrder(): OrderDetail {
        return {
            id: 0,
            createdAt: new Date(),
            updatedAt: new Date(),
            order_cost: 0,
            original_delivery_cost: 0,
            real_delivery_cost: 0,
            sum_paid: 0,
            coupon_discount: 0,
            percent_discount: 0,
            total_price_with_tax: 0,
            total_price_without_tax: 0,
            total_tax: 0,
            is_paid: false,
            delivery_filled: false,
            delivery_name: "",
            delivery_street: "",
            delivery_building_number:"",
            delivery_flat_number: "",
            delivery_city: "",
            delivery_postcode: "",
            payment_name: "",
            payment_surname: "",
            payment_street: "",
            payment_building_number: "",
            payment_flat_number: "",
            payment_city: "",
            payment_postcode: "",
            notes: "",
            phone: "",
            email: "",
            delivery_method: {
                id: 0,
                description: "",
                name: ""
            },
            delivery_country: 0,
            order_status: 1,
            tax: 1,
            payment_method: 0,
            payment_country: this.bonusStore.countUsedBonuses || 0,
            bonus_to_spend: 0,
            order_items: [],
            delivery_address: "",
        }
    }

}

export default OrderStore;
